import React, { useState } from 'react';
import Link from 'next/link';
import Nav from './nav';
import Image from "next/image";
import SignUpForUpdatesForm from './SignUpForUpdatesForm'

export default function Footer({ settings }) {

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const closeModal = () => {
        setSideNav(false);
    };

    return (
        (<footer className='footer'>
            <div className="container text-center">
                {/* <div id="footerLogo">
                
            <Link href='/'>
          <Image
              src='/img/logo/logo_vertical_white_homeschool.png'
              width={236}
              height={200}
              alt='SchoolBox Top Logo'
            />
          </Link>
                </div> */}
                <div id="footerHerzog">
                    <div className="herzogLogo">
                        <span>A Product of</span>
                        <Link href='https://herzogfoundation.com' >
                            <img src="img/home/logo_herzog.svg" />
                        </Link>
                    </div>
                </div>
                {/* <div id="footerNav">
                    <Nav />
                </div> */}
                <div id="footerBtn">
                    {settings.SITE_COMING_SOON_MODE.value == 'false' &&
                        <Link href="/signin" className="button btn-outline-white">
                            <i className="far fa-user-circle"></i>Account Login
                        </Link>
                    }
                </div>
                <div id="copyright" >Copyright {new Date().getFullYear()} - Herzog Foundation - All Rights Reserved</div>
            </div>
            <SignUpForUpdatesForm
                show={show}
                handleClose={handleClose}
            />
        </footer>)
    );
}
