import Link from 'next/link';
export default function Nav() {
  return (
    (<ul>
      <li className='menu-item'>
        <Link href='/#about' title='About'>
          About
        </Link>
      </li>
      <li className='menu-item'>
        {/* &gt;{' '} */}
        <Link href='/#whats-inside' title="What's Inside">
          What&apos;s Inside
        </Link>
      </li>
      <li className='menu-item'>
        {/* &gt;{' '} */}
        <Link href='/#eligibility' title='Eligibility'>
          Eligibility
        </Link>
      </li>
      <li className='menu-item'>
        {/* &gt;{' '} */}
        <Link href='/contributors' title='Eligibility'>
          Our Contributors
        </Link>
      </li>
    </ul>)
  );
}
